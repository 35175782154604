import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import QRCode from 'qrcode.react'
import { setAuthToken, checkAuthToken, clearAuthToken } from 'helpers/auth'
import { redirectToApp } from 'helpers/deviceDetect'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'

import Context from 'components/common/Context'
import Form from 'components/common/Form'
import Layout from 'components/common/Layout'

const Countdown = () => (
	<CountdownCircleTimer
		isPlaying
		duration={30}
		size={70}
		strokeWidth={5}
		onComplete={() => [true, 0]}
		colors={[
			['#28a745', 0.33],
			['#ffc107', 0.33],
			['#dc3545', 0.33],
		]}
	>
		{({ remainingTime }) => <div style={{ fontSize: 25 }}>{remainingTime}</div>}
	</CountdownCircleTimer>
)

export const QRCodeAuth = ({ value }) => {
	return (
		<>
			<div style={{ textAlign: 'left' }}>
				<div className="step">
					<FormattedMessage id="firstStep" />
				</div>
				<div className="step">
					<FormattedMessage id="secondStep" />
				</div>
				<div className="step">
					<FormattedMessage id="thirdStep" />
				</div>
			</div>
			{/* <h4>
        <FormattedMessage id="scanQR" />
      </h4> */}

			<hr />
			{value ? (
				<>
					<QRCode size={246} value={value} />
					<hr />
					<Countdown />
				</>
			) : (
				<div>
					<h3>
						<FormattedMessage id="loadingQR" />
					</h3>
				</div>
			)}
		</>
	)
}

export const MessageBox = ({ value, status }) => {
	const text = [-11, -5, -7].includes(+status) && (
		<FormattedMessage id={`status${status}`} />
	)
	return (
		<div>
			<h3>{text ? text : value}...</h3>
			{Number(status) === -7 && (
				<Button
					color="primary"
					variant="contained"
					style={{ marginTop: 15 }}
					onClick={() => window.history.go(-2)}
				>
					<FormattedMessage id="back" />
				</Button>
			)}
		</div>
	)
}

export const PhoneAuth = ({ onSubmit }) => {
	return (
		<>
			<h4>Fill in your phone number</h4>
			<hr />
			<Form form="login" onSubmit={onSubmit} />
		</>
	)
}

export default class extends React.Component {
	constructor(props) {
		super(props)
		redirectToApp()
	}

	static contextType = Context

	state = {
		loading: false,
		byPhone: false,
		qr_code: null,
		msg: null,
		oauth2_id: null,
		status: null,
	}

	isOauthRequest = () => this.queryData.client_id !== undefined

	setLoading = loading => this.setState({ loading: loading })
	setByPhone = byPhone => this.setState({ byPhone: byPhone })

	authenticate = token => {
		// const { location } = this.props;

		this.setLoading(true)

		try {
			setAuthToken(token)
			window.localStorage.setItem('token', token)
			this.setLoading(false)
		} catch (err) {
			console.log('Error', err)
			this.setLoading(false)
		}
	}

	onSubmit = async requestData => {
		const { location } = this.props
		this.setLoading(true)

		try {
			const { data } = await axios.post(
				`${process.env.API}/signin/`,
				requestData
			)

			setAuthToken(data.access_token)
			this.dispatchUserAction({ type: 'SAVE_USER', payload: data })
			window.localStorage.setItem('token', data.access_token)

			if (this.isOauthRequest()) {
				this.dispatchUserAction({
					type: 'OAUTH_REQUEST',
					payload: this.queryData,
				})
				this.setLoading(false)
				return navigate('/' + location.search)
			}

			this.setLoading(false)
			return navigate('/')
		} catch (err) {
			console.log('Error', err)
			this.setLoading(false)
		}
	}

	redirect = url => {
		// console.log('>> allowed: ', data.allowed)
		if (!url) return
		this.setLoading(true)
		if (!clearAuthToken(_ => { })) { }
		//window.location = data.headers.Location.replace('http:', 'https:')
		window.location = url;
	}

	navigateToConfirmIfNeeded = async () => {
		const { location } = this.props
		const loggedIn = await checkAuthToken(this.dispatchUserAction)
		if (loggedIn && this.isOauthRequest()) {
			this.dispatchUserAction({
				type: 'OAUTH_REQUEST',
				payload: this.queryData,
			})
			return navigate('/confirm' + location.search)
		}
		return false
	}

	initWebSocket = () => {
		console.log('>> process.env.SOCKET_API', process.env.SOCKET_API)
		let socket = new window.WebSocket(
			`${(process.env.SOCKET_API || '').replace('v1', 'v2')}/qr/ws`
		)
		socket.onopen = e => {
			console.log(
				'[open] Connection established',
				this.queryData.client_id,
				this.queryData.transaction_id,
			)
			const socket_payload = {
				type: "generate_qr_code",
				client_id: this.queryData.client_id,
				transaction_id: this.queryData.transaction_id
			}
			console.log('[socket] payload:', socket_payload)
			socket.send(JSON.stringify(socket_payload))
		}

		socket.onmessage = event => {
			console.log(
				`[message] Data received from server: ${event.data
				} ${typeof event.data}`
			)
			const data = JSON.parse(event.data)

			this.setLoading(false)

			if (data.type === 'qr_code') {
				this.setState({ qr_code: `transaction_id=${this.queryData.transaction_id}&qr_code=${data.qr_code}` })
			} else if (data.type === 'qr_state') {
				this.setState({ msg: 'Follow the steps on your phone', status: -5 })
			} else if (data.type === 'error') {
				this.setState({msg: 'Error', status: -7})
				//   const token = data[data.type]
				//   this.authenticate(token)
			} else if (data.type === 'fail') {
				if (data.redirect_url) {
					this.redirect(data.redirect_url)
				}
			} else if (data.type === 'redirect') {
				this.redirect(data.redirect_url)
			}
		}

		socket.onclose = event => {
			console.log('>> event: ', event)
			this.setLoading(false)
			if (event.wasClean) {
				console.log(
					`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
				)
			} else {
				// e.g. server process killed or network down
				// event.code is usually 1006 in this case
				console.log('[close] Connection died')
			}
		}

		socket.onerror = error => {
			console.log(`[error] ${error.message}`)
			this.setLoading(false)
		}

		this.socket = socket
	}

	componentDidMount() {
		const { dispatchUserAction } = this.context
		const { location } = this.props
		this.dispatchUserAction = dispatchUserAction
		this.queryData = queryString.parse(location.search)
		this.initWebSocket()
		// this.navigateToConfirmIfNeeded().then(
		//     resp => resp === false && this.initWebSocket()
		// )
	}

	componentDidUpdate() { }

	componentWillUnmount() { }

	render() {
		const { byPhone, loading, qr_code, msg, status } = this.state
		return (
			<Layout>
				<div className="auth_box auth_box_change">
					<div className="auth_tab" style={{ textAlign: 'center' }}>
						{loading ? (
							<h3>Loading...</h3>
						) : msg != null ? (
							<MessageBox value={msg} status={status} />
						) : byPhone ? (
							<PhoneAuth onSubmit={this.onSubmit} />
						) : (
							<QRCodeAuth value={qr_code} />
						)}
					</div>
				</div>
			</Layout>
		)
	}
}
